import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button } from '../components/Button'
import Video from '../assets/videos/videobg.mp4'

const NotFoundPage = () => (
  <Layout hero={false}>
    <Seo title="404: Not found" />
    <NotFoundContainer>
      <NotFoundBg>
        <VideoBg src={Video} type="video/mp4" autoPlay loop muted playsInline />
      </NotFoundBg>
      <NotFoundContent>
        <NotFoundItems>
          <h1>404</h1>
          <h2>We are sorry, Page not found!</h2>
          <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
          <Button big="true" round="true" to="/">Home</Button>
        </NotFoundItems>
      </NotFoundContent>
    </NotFoundContainer>
  </Layout>
)

export default NotFoundPage

const NotFoundContainer = styled.div`
  background-color: #0c0c0c;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(0,0,0,0, 0.2) 0%,
      gba(0,0,0,0, 0.2), 100%,
    ),
    linear-gradient(180deg, rgba(0,0,0,0, 0.2) 0%, transparent, 100%),
  }
`

const NotFoundBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const NotFoundContent = styled.div`
  z-index: 3;
  height: calc(100vh -80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px)/ 2);
`

const NotFoundItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  border-radius: 50px;
  line-height: 1.1;
  font-weight: bold;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.pageBackground};

  h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    p {
      text-align: center;
      font-size: 1rem;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
    }
  }
`
